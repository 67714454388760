import 'arrive/src/arrive';
import bsModal from 'bootstrap/js/dist/modal';
import { bpHelper } from './utils';
import Offcanvas from 'bootstrap/js/dist/offcanvas';

document.arrive('.modal-canvas-button', function(element) {
  element.addEventListener('click', () => {
    const id = element.dataset.id;

    if (bpHelper.isMatching('md', true)) {
      const offcanvasElement = document.querySelector(`.offcanvas-container-${id}`);
      const offCanvas = Offcanvas.getOrCreateInstance(offcanvasElement);

      $('.offcanvas').offcanvas('hide');
      offCanvas.show();
    } else {
      const modalElement = document.getElementById(`modal-container-${id}`);
      const modal = bsModal.getOrCreateInstance(modalElement);
      modal.show();
    }
  });
});
