import 'arrive/src/arrive';

window.before_fetch_url = [];
document.addEventListener('turbo:before-fetch-request', allDisable);
document.addEventListener('ajax:send', allDisable);

document.addEventListener('turbo:render', restaureEnable);
// document.addEventListener('turbo:visit', restaureEnable);
document.addEventListener('turbo:before-cache', restaureEnable);
document.addEventListener('turbo:before-stream-render', restaureEnable);
document.addEventListener('turbo:frame-render', restaureEnable);
document.addEventListener('ajax:complete', restaureEnable);

function allDisable(event) {
  if (event.detail?.fetchOptions?.headers['X-Sec-Purpose'] === 'prefetch') {
    return;
  }
  if (!document.body.classList.contains('notebook')) {
    document.querySelectorAll('a, button, input[type="submit"]').forEach(function(item) {
      if (!item.classList.contains('always-enabled')) {
        setDisabled(item);
      }
    });
  }
  clearTimeout(window.restaureEnableTimeout);
  window.restaureEnableTimeout = setTimeout(restaureEnable, 10000);
}

function restaureEnable() {
  if (!document.body.classList.contains('notebook')) {
    document.querySelectorAll('a, button, input[type="submit"]').forEach(function(item) {
      if (item.hasAttribute('data-tmp-disabled')) {
        setEnable(item);
      }
    });
  }

  clearTimeout(window.restaureEnableTimeout);
}

function setDisabled(item) {
  item.setAttribute('disabled', 'disabled');
  item.setAttribute('data-tmp-disabled', 'disabled');
}

function setEnable(item) {
  if (item.hasAttribute('data-tmp-disabled')) {
    item.removeAttribute('disabled');
    item.removeAttribute('data-tmp-disabled');
    setEnableLabel(item);
  }
}

document.arrive('[data-turbo-disable-with]', function(element) {
  function callSetDisabledLabel() {
    setDisabledLabel(element);
  }

  if (element.getAttribute('type') === 'submit') {
    const form = element.closest('form');
    if (form) {
      form.addEventListener('submit', function() {
        setDisabledLabel(element);
      });
    }
  } else {
    element.removeEventListener('click', callSetDisabledLabel);
    element.addEventListener('click', callSetDisabledLabel);
  }
});

function setDisabledLabel(item) {
  if (!item.getAttribute('data-turbo-disable-with') || item.getAttribute('data-turbo-enable-with')) {
    return true;
  }

  const labelDisabled = item.getAttribute('data-turbo-disable-with');
  item.setAttribute('data-turbo-enable-with', item.innerHTML);

  if (item.classList.contains('btn')) {
    item.classList.add('btn--tmp-disabled');
  }

  // Termine le processus avant de modifier le label
  setTimeout(function() {
    item.innerHTML = labelDisabled;
  });
  let target = item;
  function reEnable(event) {
    if (event.detail?.fetchResponse?.header?.('X-Sec-Purpose') === 'prefetch') {
      setTimeout(() => { setEnableLabel(item); }, 100);
    } else {
      setEnableLabel(item);
    }
  }

  // if it's a link to a turbo frame then need to listen the frame instead of the link
  const turboFrame = item.getAttribute('data-turbo-frame');
  if (turboFrame && turboFrame !== '_top') {
    target = document.querySelector(`turbo-frame#${turboFrame}`);
    if (target) {
      target.addEventListener('turbo:before-fetch-response', reEnable, { once: true });
      target.addEventListener('ajax:complete', reEnable, { once: true });
      target.addEventListener('turbo:frame-render', reEnable, { once: true });
    }
  }

  let timeout = item.getAttribute('data-turbo-disable-timeout');
  timeout = timeout !== null ? parseInt(timeout, 10) : 10000;

  if (timeout > 0) {
    setTimeout(function() {
      if (target) {
        target.removeEventListener('turbo:before-fetch-response', reEnable);
        target.removeEventListener('ajax:complete', reEnable);
        target.removeEventListener('turbo:frame-render', reEnable);
      }
      setEnableLabel(item);
    }, timeout);
  }
}

function setEnableLabel(item) {
  if (item.currentTarget) {
    item = item.currentTarget;
  }

  const labelEnable = item.getAttribute('data-turbo-enable-with');
  if (!labelEnable) {
    return true;
  }

  // Supprime la classe 'btn--tmp-disabled'
  item.classList.remove('btn--tmp-disabled');

  // Supprime les attributs
  item.removeAttribute('data-turbo-enable-with');
  item.removeAttribute('disabled');

  // Met à jour le contenu HTML
  item.innerHTML = labelEnable;
}

document.addEventListener('turbo:render', function() {
  // reset auto disable
  restaureEnable();
  document.querySelectorAll('[data-turbo-enable-with]').forEach(item => {
    setEnableLabel(item);
  });
});
export { setEnableLabel };
